import type { TooltipPlacement } from 'antd/es/tooltip'
import type { FC } from 'react'

import ThemeSwitcher from '@components/ThemeSwitcher'
import RegionalMenu from '@components/header/RegionalMenu'
import useVersionPage from '@hooks/useVersionPage'

import TicketsButton from './components/TicketsButton'

type Props = {
  placementRegionalMenu?: TooltipPlacement
  placementTicketsMenu?: TooltipPlacement
}

const TabletDesktopButtons: FC<Props> = ({ placementRegionalMenu, placementTicketsMenu }) => {
  const verPage = useVersionPage()

  return (
    <>
      {!verPage && <ThemeSwitcher />}
      <RegionalMenu placement={placementRegionalMenu} />
      <TicketsButton placementMenu={placementTicketsMenu} />
    </>
  )
}

export default TabletDesktopButtons
